<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            :placeholder="packageConstants.MASTER_PACKAGE_PLACEHOLDER"
          />
        </div>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
      <div class="card-toolbar" v-if="permission.isAddAllowed">
        <button type="button" class="btn btn-primary" @click="$router.push({ name: 'add-master-package' })"><i class="bi bi-plus-circle-fill fs-4"></i>
          {{ generalConstants.ADD }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :total="total"
        :enable-items-per-page-dropdown="true"
        ref="updateProps"
        @items-per-page-change="handleSizeChange"
      >
        <template v-slot:cell-name="{ row: data }">
          {{ data.name }}
        </template>
        <template v-slot:cell-type="{ row: data }">
          {{ data.type }}
        </template>
        <template v-slot:cell-package_name="{ row: data }">
          {{ data.package_name }}
        </template>
        <template v-slot:cell-package_code="{ row: data }">
          {{ data.package_code }}
        </template>
        <template v-slot:cell-package_details="{ row: data }">
          {{ data.package_details }}
        </template>
        <template v-slot:cell-max_weight_details="{ row: data }">
          {{ data.max_weight_details }}
        </template>
        <template v-slot:cell-box_weight_details="{ row: data }">
          {{ data.box_weight_details }}
        </template>
        <template v-slot:cell-status="{ row: data }">
          <div v-if="permission.isEditAllowed ">
            <el-switch
              v-model="data.status"
              @change="updateStatus($event, data.master_package_id)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isEditAllowed || permission.isDeleteAllowed || permission.isDuplicateAllowed">
            <el-tooltip class="box-item" effect="dark" :content="generalConstants.EDIT" placement="top" v-if="permission.isEditAllowed"> 
              <button type="button" @click.prevent="$router.push({ name: 'edit-master-package', params: { id: data.master_package_id } })" class="btn btn-icon btn-light btn-sm me-2">
                <i class="bi bi-pencil-square"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" :content="generalConstants.DUPLICATE" placement="top" v-if="permission.isDuplicateAllowed">
              <button type="button" @click.prevent="duplicate(data)" class="btn btn-icon btn-light btn-sm me-2">
                <i class="far fa-clone"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" :content="generalConstants.DELETE" placement="top" v-if="permission.isDeleteAllowed">
              <button type="button" @click.prevent="remove(data.master_package_id, tableData.findIndex((i) => i.master_package_id === data.master_package_id))" class="btn btn-icon btn-light btn-sm me-2">
                <i class="bi bi-trash text-danger"></i>
              </button>
            </el-tooltip>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { notificationFire } from "@/composable/notification.js";
import { ElMessageBox } from 'element-plus';
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";

export default {
  name: "master-package-list",
  components: {
    Datatable,
  },
  setup() {
    const generalConstants = globalConstant.general;
    const packageConstants = globalConstant.package;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const total = ref(0);
    const tableData = ref([]);
    const initData = ref([]);
    const loading = ref(false);
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const searchParams = reactive({
      search: store.getters.getSearch(moduleName.value),
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
      isDuplicateAllowed: false
    })
    //Set Table header data
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: generalConstants.TYPE,
        key: "type",
        sortable: true,
        width: "w-100px",
      },
      {
        name: generalConstants.NAME,
        key: "package_name",
        sortable: true,
        width: "w-200px",
      },
      {
        name: packageConstants.PACKAGE_CODE,
        key: "package_code",
        sortable: true,
        width: "w-200px",
      },
      {
        name: packageConstants.PACKAGE_DETAILS,
        key: "package_details",
        width: "w-200px",
        sortable: false,
      },
      {
        name: packageConstants.MAX_WEIGHT_DETAILS,
        key: "max_weight_details",
        width: "w-150px",
        sortable: false,
      },
      {
        name: packageConstants.BOX_WEIGHT_DETAILS,
        key: "box_weight_details",
        width: "w-150px",
        sortable: false,
      },
      {
        name: generalConstants.STATUS,
        key: "status",
        width: "w-50px",
        sortable: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
        alignment: 'text-center'
      },
    ]);

    //Page size change event
    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    };

    //Page change event
    const handleCurrentChange = (number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      getProductMasterPackages();
    };

    //Handle search event
    const handleSearch = () => {
      tableData.value = initData.value;
      if (searchParams.search !== "") {
        let results = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], searchParams.search)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value = results;
        if (results.length == 0) {
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
      }
      store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
			total.value = tableData.value.length;
			setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
    }

    //Search values in tableData
    const searchingFunc = (obj, value) => {
      for (let key in obj) {
        if (obj[key] && obj[key].length > 0 && !Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toUpperCase().indexOf(value.toUpperCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    //Reset search value
    const resetSearch = () => {
      if(searchParams.search.length > 0){
        searchParams.search = "";
				store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
        handleSearch();
      }
    }

    //Get all master packages
    const getProductMasterPackages = async () => {
      loading.value = true;
      await ApiService.query("master-packages")
        .then(({ data }) => {
          if (data.data && data.data.length) {
            data.data.forEach((obj) => {
              obj.type = (obj.package_type == 1) ? packageConstants.EXTERNAL : packageConstants.INTERNAL;
              obj.package_details = obj.package_length + 'L x ' + obj.package_width + 'W x ' + obj.package_height + 'H (' + obj.length_class + ')';
              obj.max_weight_details = obj.max_weight + ' ' + obj.weight_class;
              obj.box_weight_details = obj.box_weight + ' ' + obj.weight_class;
              obj.status = obj.status == 1 ? true : false;
            });
            tableData.value = data.data;
            initData.value = data.data;
            total.value = data.data.length;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
          } else {
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          loading.value = false;
          handleSearch();
        })
        .catch(({ err }) => {
          loading.value = false;
          tableData.value = [];
          total.value = 0;
        });
    };

    //Update product master package status action from listing page
    const updateStatus = (status, id) => {
      ApiService.post("products-master-package/statusupdate", {
        status: status,
        id: id,
      })
        .then(({ data }) => {
          getProductMasterPackages();
          notificationFire(data.message, "success");
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    //Duplicate product master package action from listing page
    const duplicate = async (packageDetails) => {
      ElMessageBox.confirm(
        'Do you really want to duplicate this record?<br> <strong>Note: System will add the "D" in package code for maintain unique system package code.</strong>',
        generalConstants.ALERT_WARNING,
        {
          confirmButtonText: generalConstants.ALERT_CONFIMATION,
          cancelButtonText: generalConstants.ALERT_CANCEL,
          type: 'warning',
          center: true,
          dangerouslyUseHTMLString: true
        }
      )
      .then(() => {
        ApiService.post("master-packages", {
        type: "D",
        package_type: packageDetails.package_type,
        package_name: packageDetails.package_name,
        package_code: packageDetails.package_code + 'D',
        weight_class: packageDetails.weight_class,
        max_weight: packageDetails.max_weight,
        box_weight: packageDetails.box_weight,
        length_class: packageDetails.length_class,
        package_length: packageDetails.package_length,
        package_width: packageDetails.package_width,
        package_height: packageDetails.package_height,
        status: packageDetails.status,
      })
        .then(({ data }) => {
          if (data) {
            notificationFire(data.message, generalConstants.NOTIFY_SUCCESS);
            router.push({
              name: "edit-master-package",
              params: { id: data.data.master_package_id },
            });
          }
        })
        .catch(({ error }) => {
          let message='';
          if (typeof (error.response) !== 'undefined') {
            message = error.response.data.message;
          } else if (typeof (error.message) !== 'undefined') {
            message = error.message;
          } else {
            message = generalConstants.NOTIFY_WRONG_MESSAGE;
          }
          notificationFire(message, generalConstants.NOTIFY_ERROR);
        });
      });
    };

    //Remove product master package data from listing page
    const remove = async (masterPackageID, index) => {
      ElMessageBox.confirm(
        generalConstants.DELETE_MESSAGE,
        generalConstants.ALERT_WARNING,
        {
          confirmButtonText: generalConstants.ALERT_CONFIMATION,
          cancelButtonText: generalConstants.ALERT_CANCEL,
          type: 'warning',
          center: true
        }
      )
      .then(() => {
        ApiService.delete("master-packages/" + masterPackageID)
        .then((response) => {
          tableData.value.splice(index, 1);
          notificationFire(response.data.message, generalConstants.NOTIFY_SUCCESS);
          getProductMasterPackages();
        })
        .catch((error) => {
          let message='';
          if (typeof (error.response) !== 'undefined') {
            message = error.response.data.message;
          } else if (typeof (error.message) !== 'undefined') {
            message = error.message;
          } else {
            message = generalConstants.NOTIFY_WRONG_MESSAGE;
          }
          notificationFire(message, generalConstants.NOTIFY_ERROR);
        });
      });
    };

    //Set breadcrumbs and get the products
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createMasterPackage") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateMasterPackage") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("deleteMasterPackage") || role == "Super Admin")
        permission.isDeleteAllowed = true;
      if (per.includes("duplicateMasterPackage") || role == "Super Admin")
        permission.isDuplicateAllowed = true;
      await getProductMasterPackages();
    });

    return {
      tableData,
      getProductMasterPackages,
      total,
      loading,
      updateStatus,
      duplicate,
      remove,
      resetSearch,
      handleSizeChange,
      handleCurrentChange,
      handleSearch,
      searchParams,
      tableHeader,
      noDataFound,
      updateProps,
      generalConstants,
      packageConstants,
      permission
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.btn-list {
  margin-left: 10px !important;
}
</style>